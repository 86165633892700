// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    sendError: undefined,
    sendSuccess: false,
    sendElement: undefined,
});

const dismissAllErrorsState = {
    sendError: undefined,
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.ETS_INIT:
            return state.merge({
                sendError: undefined,
                sendSuccess: false,
                sendElement: undefined,
            });
        case types.ETS_EDIT_ELEMENT:
            return state.merge({
                sendElement: action.values
            });
        case types.ETS_DISMISS_ALL_ERRORS:
            return state.merge(dismissAllErrorsState);
        case types.ETS_SEND_SUCCESS:
            return state.merge({
                sendSuccess: true,
                sendError: undefined,
            });
        case types.ETS_SEND_ERROR:
            return state.merge({
                sendError: action.error,
            });
        default:
            return state;
    }
}

// selectors

export function getSendSuccessfully(state) {
    return state.ets.sendSuccess;
}
export function getSendError(state) {
    return state.ets.sendError;
}
export function getSendElement(state) {
    return state.ets.sendElement ? Immutable.asMutable(state.ets.sendElement, {deep: true}) : undefined;
}
