import jwtDecode from 'jwt-decode'
import {differenceInSeconds, fromUnixTime} from 'date-fns'
import {ACCESS_TOKEN_EXPIRE_DELTA} from "../globals/config";
import {refreshToken} from './auth/actions';
import store from "./store";

export function jwt({dispatch, getState}) {
    return (next) => (action) => {
        if (typeof action !== 'function') {
            // only worry about expiring token for async actions
            return next(action);
        }
        if (!localStorage.getItem('token')) {
            return next(action);
        }
        // decode jwt so that we know if and when it expires
        const token = getState().common.accessToken;
        const tokenExpiration = token ? fromUnixTime(jwtDecode(token).exp) : new Date();
        if (differenceInSeconds(tokenExpiration, new Date()) < ACCESS_TOKEN_EXPIRE_DELTA) {
            // make sure we are not already refreshing the token
            if (!getState().common.tokenRefreshPromise) {
                return refreshToken(dispatch)
                    .then(() => next(action))
                    // даже если не смогли обновить рефреш токен - выполняем действие - оно словит 401 а обработчик выкинет на auth
                    .catch(() => next(action));
            } else {
                return getState().common.tokenRefreshPromise
                    .then(() => next(action))
                    // даже если не смогли обновить рефреш токен - выполняем действие - оно словит 401 а обработчик выкинет на auth
                    .catch(() => next(action));
            }
        } else {
            return next(action);
        }
    };
}

export function jwtAsyncAction(action) {
    return async (...params) => {
        if (!localStorage.getItem('token')) {
            // if there is no refresh-token in localStorage we can't do anything
            return action(...params);
        }
        // decode jwt so that we know if and when it expires
        const token = store.getState().common.accessToken;
        const tokenExpiration = token ? fromUnixTime(jwtDecode(token).exp) : new Date();
        if (differenceInSeconds(tokenExpiration, new Date()) < ACCESS_TOKEN_EXPIRE_DELTA) {
            // make sure we are not already refreshing the token
            if (!store.getState().common.tokenRefreshPromise) {
                await refreshToken(store.dispatch)
                return action(...params);
            } else {
                await store.getState().common.tokenRefreshPromise
                return action(...params);
            }
        } else {
            return action(...params);
        }
    };
}