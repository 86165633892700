// services are state-less
// they act as utility facades that abstract the details for complex operations
// normally, our interface to any sort of server API will be as a service
import _ from 'lodash'
import * as config from '../globals/config';
import BackendSession from '../globals/session';
import {BACKEND_ROOT} from "../globals/config";

export class BackendServiceCommon {
    // ********* Служебные
    constructor() {
        this.abortController = new AbortController();
        BackendServiceCommon.loadUser();
    }

    backEndPoint = (service, storage=false) => {
        const res = BackendServiceCommon.backendUrl(storage);
        return res.replace("#port#", _.get(config.SERVICE_PORT_MAPPING, service, 80))
    };

    static getBodyFromElement(element, keys, numKeys) {
        let res = {};
        for (let k in keys) {
            // добавим поля в данные - кроме файловых полей
            if (!keys.hasOwnProperty(k)) { continue }
            if (element[keys[k]] === undefined || element[keys[k]] === null) {
                continue
            }
            if (numKeys.includes(k)) {
                res[k] = Number(element[keys[k]])
            } else {
                res[k] = element[keys[k]]
            }
        }
        return res;
    }

    getRefreshToken() {
        return localStorage.getItem('token');
    }

    setRefreshToken(token) {
        localStorage.setItem('token', token);
    }

    clearRefreshToken() {
        localStorage.clear();
        // localStorage.removeItem("token");
    }

    async checkResponse(response, generalMessage, method='GET') {
        if (response.ok) {
            return
        }
        if (response.status === 500) {
            const err = await response.json();
            throw new Error(`[${response.status}] - ${err.error}`);
        } else if (response.status === 401) {
            BackendServiceCommon.clearUser();
            window.location.href=`/`;
        } else if (response.status === 403) {
            if (method === 'GET') {
                window.location.href=`/`;
            } else {
                throw new Error(`[${response.status}] - У вас недостаточно прав для выполнения данного действия`);
            }
        } else {
            throw new Error(`[${response.status}] - ${generalMessage}`);
        }
    }

    static setUser(id, login, name, privileges, role) {
        BackendSession.userId = id;
        BackendSession.userLogin = login;
        BackendSession.userName = name;
        BackendSession.userPrivileges = privileges;
        BackendSession.userRole = role;
        localStorage.setItem('userId', id);
        localStorage.setItem('userLogin', login);
        localStorage.setItem('userName', name);
        localStorage.setItem('userRole', role);

        for (let prop in config.SECTIONS_NAMES) {
            if (privileges.hasOwnProperty(prop)) {
                localStorage.setItem(`userPrivilege_${prop}_`, privileges[prop]);
            }
        }
    }

    static loadUser() {
        BackendSession.userId = localStorage.getItem('userId');
        BackendSession.userLogin = localStorage.getItem('userLogin');
        BackendSession.userName = localStorage.getItem('userName');
        BackendSession.userRole = localStorage.getItem('userRole');

        BackendSession.userPrivileges = {};
        for (let prop in config.SECTIONS_NAMES) {
            BackendSession.userPrivileges[prop] = localStorage.getItem(`userPrivilege_${prop}_`);
        }
    }

    static clearUser() {
        BackendSession.userId = undefined;
        BackendSession.userLogin = undefined;
        BackendSession.userName = undefined;
        BackendSession.userPrivileges = undefined;
        BackendSession.userRole = undefined;
        localStorage.removeItem('userId');
        localStorage.removeItem('userLogin');
        localStorage.removeItem('userName');
        localStorage.removeItem('userRole');
        for (let prop in config.SECTIONS_NAMES) {
            localStorage.removeItem(`userPrivilege_${prop}_`);
        }
    }

    static backendUrl(storage=false) {
        //https://stackoverflow.com/questions/6941533/get-protocol-domain-and-port-from-url
        if (config.DEBUG_BACK_ENDPOINT) {
            return storage ? BACKEND_ROOT + 'storage/' : config.DEBUG_BACK_ENDPOINT;
        }
        const url = window.location.href;
        const arr = url.split("/");
        const urlPath = storage ? "/storage/" : "/api/v1/"
        return arr[0] + "//" + arr[2] + urlPath;
    }

    async login(login, password, fingerprint) {
        const url = `${this.backEndPoint(config.SERVICE_AUTH_NAME)}auth/login`;
        let formData = new FormData();
        formData.append('login', login);
        formData.append('password', password);
        formData.append('fingerprint', fingerprint);
        const rawResponse = await fetch(url, {
            method: 'POST',
            body: formData,
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            this.clearRefreshToken();
            BackendServiceCommon.clearUser();
            throw new Error(`Ошибка авторизации, HTTP status ${rawResponse.status}`);
        }
        const res = await rawResponse.json();
        if (!res.data) {
            this.clearRefreshToken();
            BackendServiceCommon.clearUser();
            throw new Error(`Ошибка авторизации, HTTP status ${rawResponse.status}`);
        }
        this.setRefreshToken(res.data['refresh_token']);
        let privileges = {};
        for (let prop in res.data.privileges) {
            if (!res.data.privileges.hasOwnProperty(prop)) { continue }
            privileges[prop] = res.data.privileges[prop];
        }
        BackendServiceCommon.setUser(res.data.id, res.data.login, res.data.name, privileges, res.data.role);
        return res.data['access_token'];
    }

    async refreshToken(fingerprint) {
        const url = `${this.backEndPoint(config.SERVICE_AUTH_NAME)}auth/refresh`;
        let formData = new FormData();
        formData.append('refresh', this.getRefreshToken());
        formData.append('fingerprint', fingerprint);
        const rawResponse = await fetch(url, {
            method: 'POST',
            body: formData,
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            this.clearRefreshToken();
            BackendServiceCommon.clearUser();
            throw new Error(`Ошибка авторизации, HTTP status ${rawResponse.status}`);
        }
        const res = await rawResponse.json();
        if (!res.data) {
            this.clearRefreshToken();
            BackendServiceCommon.clearUser();
            throw new Error(`Ошибка авторизации, HTTP status ${rawResponse.status}`);
        }
        this.setRefreshToken(res.data['refresh_token']);
        let privileges = {};
        for (let prop in res.data.privileges) {
            if (!res.data.privileges.hasOwnProperty(prop)) { continue }
            privileges[prop] = res.data.privileges[prop];
        }
        BackendServiceCommon.setUser(res.data.id, res.data.login, res.data.name, privileges, res.data.role);
        return res.data['access_token'];
    }

    async logout() {
        const url = `${this.backEndPoint(config.SERVICE_AUTH_NAME)}auth/logout`;
        let formData = new FormData();
        formData.append('refresh', this.getRefreshToken());
        const rawResponse = await fetch(url, {
            method: 'POST',
            body: formData,
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            throw new Error(`Ошибка завершения авторизованной сессии, HTTP status ${rawResponse.status}`);
        }
        this.clearRefreshToken();
        BackendServiceCommon.clearUser();
    }
}

export default new BackendServiceCommon();
