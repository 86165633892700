// import { reducer as formReducer } from 'redux-form'
import { combineReducers } from 'redux'

import auth from './auth/reducer';
import common from './common/reducer';
import ets from './ets/reducer';

export default combineReducers({
    auth,
    ets,
    common,
});
