// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
// there are 3 types of actions:
//  async thunks - when doing asynchronous business logic like accessing a service
//  sync thunks - when you have substantial business logic but it's not async
//  plain object actions - when you just send a plain action to the reducer

import fp from "fingerprintjs2";
import * as types from './actionTypes';
import * as commonTypes from '../common/actionTypes';
import BackendServiceCommon from '../../services/serverCommon';

export function login(authData) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOGIN_CLEAR});
        dispatch({type: types.LOGIN_IN_PROGRESS});
        try {
            const fingerprint = await fetchBrowserFingerprint();
            const token = await BackendServiceCommon.login(authData.login, authData.password, fingerprint);
            dispatch({type: types.LOGIN_SUCCESS, result: true});
            dispatch({type: commonTypes.COMMON_SET_ACCESS_TOKEN, token});
        } catch (error) {
            console.error('Login error', error);
            dispatch({type: types.LOGIN_FAILED, result: false});
            dispatch({type: commonTypes.COMMON_SET_ACCESS_TOKEN, 'token': undefined});
        }
    };
}

export function logout() {
    return async (dispatch, getState) => {
        try {
            await BackendServiceCommon.logout();
            dispatch({type: commonTypes.COMMON_SET_ACCESS_TOKEN, 'token': undefined});
        } catch (error) {
            dispatch({type: commonTypes.COMMON_SET_ACCESS_TOKEN, 'token': undefined});
        }
    };
}

async function fetchBrowserFingerprint() {
    // todo: добавить кэширование было бы весьма кстати, ИМХО
    return new Promise((resolve, reject) => {
        fp.get(components=> resolve(fp.x64hash128(components.join(''), 31)));
    });
}

async function fetchJWTToken() {
    try {
        const fingerprint = await fetchBrowserFingerprint();
        return await BackendServiceCommon.refreshToken(fingerprint);
    } catch (error) {
        return undefined;
    }
}

export function refreshToken(dispatch) {
    const freshTokenPromise = fetchJWTToken()
        .then(t => {
            dispatch({type: commonTypes.COMMON_SET_ACCESS_TOKEN, token: t});
            dispatch({type: commonTypes.COMMON_REFRESH_TOKEN_DONE});
            return t ? Promise.resolve(t) : Promise.reject({message: 'could not refresh token'});
        })
        .catch(e => {
            console.error('error refreshing token', e);
            dispatch({type: commonTypes.COMMON_REFRESH_TOKEN_DONE});
            return Promise.reject(e);
        });

    // we want to keep track of token promise in the state so that we don't try to refresh
    // the token again while refreshing is in process
    dispatch({type: commonTypes.COMMON_REFRESH_TOKEN_BEGIN, promise: freshTokenPromise});

    return freshTokenPromise;
}
