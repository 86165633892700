// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
import React, {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import store from './store/store';
import ErrorBoundary from "./components/ErrorBoundary";
import LoadingFallback from "./components/LoadingFallback"

const AuthScreen = lazy(() => import('./containers/AuthScreen'));
const EtsScreen = lazy(() => import('./containers/EtsScreen'));
const NotFound = lazy(() => import('./containers/NotFound'));

// todo: нормальное сообщение "Загрузка"
const routes =
    <App>
        <ErrorBoundary>
            <Suspense fallback={<LoadingFallback />}>
                <Switch>
                    <Route exact path='/test/' component={EtsScreen}/>
                    <Route exact path='/' component={AuthScreen}/>
                    <Route component={NotFound}/>
                </Switch>
            </Suspense>
        </ErrorBoundary>
    </App>;

ReactDOM.render(
	<React.StrictMode>
	    <Provider store={store}>
	        <BrowserRouter>{routes}</BrowserRouter>
	    </Provider>
	</React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
