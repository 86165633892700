// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    accessToken: undefined,
    tokenRefreshPromise: undefined,
    infoMessage: false,
    isFormSubmitting: false,
});

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.COMMON_REFRESH_TOKEN_BEGIN:
            return state.merge({
                tokenRefreshPromise: action.promise,
            });
        case types.COMMON_REFRESH_TOKEN_DONE:
            return state.merge({
                tokenRefreshPromise: undefined,
            });
        case types.COMMON_SET_ACCESS_TOKEN:
            return state.merge({
                accessToken: action.token,
            });
        case types.COMMON_SHOW_INFO_ALERT:
            return state.merge({
                infoMessage: action.data,
            });
        case types.COMMON_HIDE_INFO_ALERT:
            return state.merge({
                infoMessage: undefined,
            });
        case types.COMMON_SUBMITTING:
            return state.merge({
                isFormSubmitting: action.submitting
            });
        default:
            return state;
    }
}

// selectors

export function getInfoMessage(state) {
    return state.common.infoMessage;
}

export function isFormSubmitting(state) {
    return state.common.isFormSubmitting;
}

export function getAccessToken(state) {
    return state.common.accessToken;
}
