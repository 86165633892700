export const SUB_TABLES_PAGE_SIZE_DEFAULT = 12;
export const TABLES_PAGE_SIZE_DEFAULT = 20;
export const TABLES_PAGE_SIZES = [10, 12, 20, 30, 40, 50, 100];
export const ACCESS_TOKEN_EXPIRE_DELTA = 10; // 10 сек до конца токена считаем что всем абзац и надо рефрешить

let DEBUG;
try {
    DEBUG = require('../.debug');
} catch (e) {
    DEBUG = false
}
let DEBUG_BACK_ENDPOINT;
let BACKEND_ROOT;
if (DEBUG) {
    DEBUG_BACK_ENDPOINT = 'http://localhost:#port#/api/v1/';
    BACKEND_ROOT = 'http://localhost:#port#/';
} else {
    DEBUG_BACK_ENDPOINT = undefined;
    BACKEND_ROOT = ''
}
export {DEBUG_BACK_ENDPOINT};
export {BACKEND_ROOT};

export const FILTER_TYPES = {
    DATE: 'date-filter',
    LIST: 'list-filter',
    SELECT: 'select-filter',
};

export const DATE_FORMATS = {
    DATE_YEAR: "yyyy",
    DATE_MONTH: "yyyy MMM",
    DATE: "dd.MM.yyyy",
    DATE_TIME: "dd.MM.yyyy HH:mm:SS",
};

export const SECTIONS = [
    "cards",
    "admin",
    "visits",
    "decisions",
    "intoxication_exams",
    "hospitalizations",
    "tribunal_duties",
    "drugs_exams",
    "additional_info",
    "medical_exams",
    "journal",
];

export const SECTIONS_NAMES = {
    admin: "admin",
    cards: "cards",
    visits: "visits",
    decisions: "decisions",
    intoxication_exams: "intoxication_exams",
    hospitalizations: "hospitalizations",
    tribunal_duties: "tribunal_duties",
    drugs_exams: "drugs_exams",
    additional_info: "additional_info",
    medical_exams: "medical_exams",
    journal: "journal",
};

export const TAB_SECTIONS = [
    SECTIONS_NAMES.visits,
    SECTIONS_NAMES.decisions,
    SECTIONS_NAMES.intoxication_exams,
    SECTIONS_NAMES.hospitalizations,
    SECTIONS_NAMES.tribunal_duties,
    SECTIONS_NAMES.drugs_exams,
    SECTIONS_NAMES.medical_exams,
    SECTIONS_NAMES.additional_info,
];

export const SECTIONS_LABELS = {
    admin: "админка",
    cards: "картотека",
    visits: "посещение врача",
    decisions: "решение ВК",
    intoxication_exams: "экп. на опьянение",
    hospitalizations: "госпитализация",
    tribunal_duties: "обяз. по суду",
    drugs_exams: "нарк. эксп.",
    additional_info: "доп. сведения",
    medical_exams: "обследования",
    journal: "журнал",
};

export const SECTIONS_EXPORT = {
    admin: false,
    cards: true,
    visits: false,
    decisions: false,
    intoxication_exams: false,
    hospitalizations: false,
    tribunal_duties: false,
    drugs_exams: false,
    additional_info: false,
    medical_exams: false,
    journal: false,
};

export const TOOLBAR_ACTIONS = {
    COPY_TO_CLIPBOARD: 100500,
    READ: 0,
    CREATE: 1,
    EDIT: 2,
    MULTI_EDIT: 20,
    DELETE: 3,
    EXPORT: 4,
    IMPORT: 5,
    READ_EXTERNAL: 6,
};

export const FILTER_TIMEOUT = 500;
export const FILTER_TIMEOUT_SMALL = 333;
export const FILTER_PLACEHOLDER = 'Выбрать';

export const CTRL_KEY = 1;
export const SHIFT_KEY = 2;

export const SERVICE_AUTH_NAME = 'auth';
export const SERVICE_ADMIN_NAME = 'admin';
export const SERVICE_CARDS_NAME = 'cards';
export const SERVICE_PORT_MAPPING = {
    [SERVICE_AUTH_NAME]: 8080,
    [SERVICE_ADMIN_NAME]: 8180,
    [SERVICE_CARDS_NAME]: 8280,
};

export const DATE_ZERO_VALUE = -62135596800;
export const QUESTIONS = {
    q1: "1. Объектами видеонаблюдения являются",
    q2: "2. Трансляция из аудиторий ППЭ осуществляется в режиме реального времени",
    q3: "3. Тестирование системы видеонаблюдения проводится в целях",
    q4: "4. Произвести включение/выключение режима записи в аудиториях и штабе ППЭ, проверить работоспособность средств видеонаблюдения во всех аудиториях и штабе ППЭ дает указание",
    q5: "5. При возникновении нештатных ситуаций (видеозапись не ведется, или установить факт ведения видеозаписи не представляется возможным) технический специалист информирует о возникших проблемах",
    q6: "6. Поступающую информацию о нарушениях, зафиксированных в ППЭ, технический специалист передает",
    q7: "7. Запись видеоизображения может производится на",
    q8: "8. Какое Имя пользователя и пароля при в ходе в операционную систему?",
    q9: "9. Для открытия страницы видеонаблюдения куда необходимо ввести «localhost»?",
    q10: "10. Какой логин и пароль для входа в систему видеонаблюдения AVS?",
    q11: "11. Какое действие выполняет данная камера?",
    q12: "12. Какое действие выполняет данная камера?",
    q13: "13. Какое действие выполняет данная камера?",
    q14: "14. О чем сообщает система AVS на данной камере?",
    q15: "15. О чем сообщает система AVS на данной камере?",
    q16: "16. О чем сообщает система AVS на данной камере?",
    q17: "17. О чем сообщает система AVS на данной камере?",
    q18: "18. О чем сообщает система AVS на данной камере?",
    q19: "19. О чем сообщает система AVS?",
    q20: "20. О чем сообщает система AVS?",
    q21: "21. О чем сообщает система AVS?",
    q22: "Тел. тех.под. по видеонабл. АО «Калуга Астрал»",
    q23: "Тел. технической поддержки портала smotiege.ru",
    q24: "Ваше имя (ФИО)",
    q25: "Ваша должность",
    q26: "№ ППЭ",
}