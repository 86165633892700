import _ from "lodash";

class BackendSession {
    constructor() {
        this.userId = undefined;
        this.userName = undefined;
        this.userLogin = undefined;
        this.userRole = undefined;
        this.userPrivileges = undefined;
    }

    authorizeRead(sectionName) {
        const privilegeValue = _.get(this.userPrivileges, sectionName, 0);
        // для чтения проверяем 0-й или 1-й бит (запись гарантирует чтение)
        return ((privilegeValue & 0x3) > 0)
    }

    authorizeWrite(sectionName) {
        const privilegeValue = _.get(this.userPrivileges, sectionName, 0);
        // для записи важно проверить именно 1-й бит
        return ((privilegeValue & 0x2) > 0)
    }

    authorizeExport(sectionName) {
        const privilegeValue = _.get(this.userPrivileges, sectionName, 0);
        // для экспорта важно проверить именно 2-й бит
        return ((privilegeValue & 0x4) > 0)
    }
}

export default new BackendSession();